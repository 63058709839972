
.custom-content {
    margin-top: 18px;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    padding-bottom: 52px;

    .custom-wrap {
        margin: 0 20px;
        flex: 1;
        height: 1%;
        display: flex;
        flex-direction: column;

        .item-content {
            display: flex;
            align-items: center;

            .divide {
                width: 20px;
                height: 2px;
                background: #D2D2D2;
                margin: 0 10px;
            }

            .file-upload-btn {
                display: none;
            }

            .upload-text {
                margin-left: 11px;
                font-size: 14px;
                color: #333333;
            }

            .percent-box {
                margin-left: 10px;
                width: 100px;

                i {
                    color: #2DC079;
                }
            }
        }

        .page-button {
            text-align: center;
        }
    }
}
